import Image, { ImageProps } from 'next/image';
import { CloudflareLoader } from './image-loaders';

function CdnImage({ alt, ...restProps }: ImageProps) {
    return <Image alt={alt || 'Unknown image'} {...restProps} loader={CloudflareLoader} />;
}
function UmbracoImage({ alt, ...restProps }: ImageProps) {
    return <Image alt={alt || 'Unknown image'} {...restProps} />;
}

export { CdnImage, UmbracoImage };
