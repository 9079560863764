import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import Input from '~/shared/form-elements/input/input.component';
import Button from '~/shared/buttons/button/button.component';
import styles from './email-sign-up-form.module.scss';
import { IWithClassName } from '~/models/dev';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Text from '~/shared/text/text.component';
import ErrorBox from '../error-box/error-box';
import { useLayoutDispatch, addSuccessToast } from '~/context/layout.context';
import signUpNewsLetter from '~/services/user/sign-newsLetters.service';
import RawHtmlComponent from '~/page-elements/raw-html/raw-html.component';
import { GA4NewsletterSignup } from '~/libs/ga4';
import useUser from '~/libs/use-user';

type ISignUpForm = IWithClassName;
interface IEmailData {
    email: string;
}

const EmailSignUpForm: FC<ISignUpForm> = ({ className }) => {
    const translate = useTranslations();
    const { user } = useUser();
    const { register, handleSubmit, reset } = useForm<IEmailData>({
        defaultValues: {
            email: '',
        },
    });
    const [isError, setError] = useState(false);
    const dispatch = useLayoutDispatch();

    const onSubmit = async (emailData: IEmailData) => {
        // Add submit handler
        try {
            await signUpNewsLetter(emailData?.email, user);
            window.setTimeout(() => {
                dispatch(addSuccessToast(translate('common.successfullyCreated', 'Oprettet med succes')));
            }, 300);
            reset({ email: '' });
            GA4NewsletterSignup();
        } catch (error) {
            setError(true);
        }
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)} className={clsx(styles.formWrapper, className)}>
            <Text textAlign="center" tagName="h3" fontWeight="semiBold" className={styles.text}>
                {translate('signUpForm.signUpTitle', 'Få FTZ’s tilbud på mail')}
            </Text>
            <div className={styles.inputWrapper}>
                <Input
                    {...register('email')}
                    type="email"
                    name="email"
                    className={styles.inputPlaceholder}
                    aria-label={translate('signUpForm.placeholderEmail', 'E-mail')}
                    wrapperClassName={styles.input}
                    placeholder="Dine e-mail" // ? need to add translation */
                />
                <Button type="submit" buttonStyle="secondary" buttonSize="default" className={styles.button}>
                    {translate('signUpForm.submitButton', 'Tilmeld')}
                </Button>
            </div>
            <RawHtmlComponent
                content={translate(
                    'signUpForm.emailConsent',
                    'Ved at tilmelde mig, giver samtykke til, at FTZ må sende mig e-mails med tilbud, nyheder samt begivenheder. Du kan til enhver tid trække samtykket tilbage og afmelde dig nyhedsbrevet. Jeg accepterer samtidig, at FTZ kan behandle personoplysninger om mig. Du kan i vores persondatapolitik læse mere om, hvordan vi behandler dine personoplysninger, samt hvilke rettigheder du har.',
                )}
            />
            {isError && <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>}
        </form>
    );
};

export default EmailSignUpForm;
