import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { apiClient } from '~/services/api-client';
import { Session } from 'next-auth';

const signUpNewsLetter = (email: string, user: Session | null) => {
    const url = createUrl({
        endpoint: endpoints.users.signUpNewsletter,
        localeOption: LocaleOptions.omit,
        query: { email },
    });

    return apiClient.auth(user).post(url);
};

export default signUpNewsLetter;
