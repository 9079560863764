import React from 'react';
import { useLayoutState } from '~/context/layout.context';
import { ISlug } from '~/models/dev';
import Footer from '~/shell/footer/footer.component';
import HeaderRoot from '~/shell/header-root/header-root.component';
import Loader from '~/shared/loader/loader.component';

import styles from './root.module.scss';

type RootLayoutContainerProps = {
    children: React.ReactNode;
};

export const RootLayoutContainer = ({ children }: RootLayoutContainerProps) => <div className={styles.page}>{children}</div>;

export const RootLayoutContentContainer = ({ children }: RootLayoutContainerProps) => <main className={styles.main}>{children}</main>;

export const RootLayoutContent = ({ children }: RootLayoutContainerProps) => <article className={styles.article}>{children}</article>;

const RootLayout = ({ children }: ISlug) => {
    const { loading, isRedirecting } = useLayoutState();

    return (
        <RootLayoutContainer>
            <HeaderRoot />

            <RootLayoutContentContainer>
                <RootLayoutContent>{children}</RootLayoutContent>

                <Loader isVisible={!!loading || !!isRedirecting} className={styles.loader} delay={0.2} fixed />
            </RootLayoutContentContainer>

            <Footer className={styles.footer} />
        </RootLayoutContainer>
    );
};

export default RootLayout;
