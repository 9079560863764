import React, { FC } from 'react';
import clsx from 'clsx';
import { CMSLink, Link } from '~/shared/link';
// import { useMediaQueryDetect, MediaQuery } from '~/shared/media-query-detect'
import { useStaticContent } from '~/libs/queries/bff';
import { IWithClassName } from '~/models/dev';
// import SignUpForm from '~/shared/sign-up-form/sign-up-form.component'
import useTranslations from '~/shared/hooks/use-translations.hook';
import { LayoutActionTypes, useLayoutDispatch } from '~/context/layout.context';
import styles from './footer.module.scss';
import useUser from '~/libs/use-user';
import Heading from '~/shared/heading/heading.component';
import { CdnImage } from '~/shared/image/cdn-image';
import EmailSignUpForm from '~/shared/email-sign-up-form/email-sign-up-form.component';
import { useRouter } from 'next/router';
import { rootPage } from '~/constants/language';

const Footer: FC<IWithClassName> = ({ className }) => {
    const { isLoggedIn, isInternalUser } = useUser();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const { footer, isSuccess } = useStaticContent();
    const { asPath } = useRouter();
    const isRootPage = asPath === rootPage;

    // const isPortable = useMediaQueryDetect(MediaQuery.MD)
    const translate = useTranslations();
    const dispatch = useLayoutDispatch();

    const handleClickErrorReport = () => {
        dispatch({
            type: LayoutActionTypes.OpenErrorReport,
            payload: {},
        });
    };

    if (!footer) {
        return null;
    }

    return (
        <footer className={clsx(styles.footer, className)}>
            {!isInternalUser && isLoggedIn && isSuccess && !isRootPage ? (
                <section className={styles.singUpWrapper}>
                    <EmailSignUpForm />
                </section>
            ) : null}

            <div className={clsx('container-fluid', styles.content)}>
                <section>
                    <Heading tagName="h4" className={styles.sectionTitle}>
                        {translate('footer.addressTitle', 'FTZ Autodele & Værktøj A/S')}
                    </Heading>

                    <p className={styles.text}>
                        {footer?.contact?.streetAddress}
                        <br />
                        {footer?.contact?.zipCode} {footer?.contact?.city}
                    </p>

                    <p className={styles.text}>
                        {`${translate('common.phone', 'Telefon')}: `}
                        <Link href={`tel:${footer?.contact?.phone}`}>{footer?.contact?.phone}</Link>
                        <br />
                        {translate('common.fax', 'Telefon')}: {footer?.contact?.fax}
                        <br />
                        {`${translate('common.email', 'E-mail')}: `}
                        <Link href={`mailto:${footer?.contact?.email}`}>{footer?.contact?.email}</Link>
                        <br />
                        {translate('common.cvrNumber', 'CVR-nummer')}: {footer?.contact?.cvr}
                    </p>
                </section>

                <section>
                    <Heading tagName="h4" className={styles.sectionTitle}>
                        {translate('footer.linksTitle', 'Genveje')}
                    </Heading>

                    {footer?.links?.map(
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        (link, i) =>
                            link && (
                                <CMSLink className={styles.link} link={link} key={i} prefetch={false}>
                                    {link.title}
                                </CMSLink>
                            ),
                    )}

                    {/* TODO: Use check from BE to render the link */}
                    {isLoggedIn && (
                        <Link className={styles.link} onClick={handleClickErrorReport}>
                            {translate('errorReport.reportError', 'Meld fejl')}
                        </Link>
                    )}
                </section>

                <section>
                    {footer?.image?.url && (
                        <CdnImage
                            sizes="500px"
                            width="500"
                            height="500"
                            src={footer?.image.url}
                            alt={footer?.image.altText}
                            layout="responsive"
                            objectFit="contain"
                        />
                    )}
                </section>

                {/*
          Ready for implementation of sigh up flow

          {!isPortable && (
            <section>
              <h6 className={styles.sectionTitle}>
                {translate('footer.signUpFormTitle', 'Tilmeld nyhedsbrev')}
              </h6>

              <SignUpForm />
            </section>
          )}
        */}
            </div>
        </footer>
    );
};

export default Footer;
