import { ImageLoaderProps } from 'next/image';

const cdnImageHost = process.env.NEXT_PUBLIC_CDN_IMAGE_HOST || '';

const UmbracoLoader = ({ src = null, width = 64, quality = 75 }) => {
    let urlParameterDelimiter = '?';
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (src.indexOf('?') > -1) {
        urlParameterDelimiter = '&';
    }
    return `${src}${urlParameterDelimiter}width=${width}&quality=${quality}`;
};

// https://developers.cloudflare.com/images/image-resizing/integration-with-frameworks/
const CloudflareLoader = ({ src, width, quality }: ImageLoaderProps) => {
    const url = new URL(src);

    // console.log('Image', url)

    // Resize is unnecessary for vector-based SVG images but we still want to point to cloudflare's CDN
    if (url.pathname.endsWith('.svg')) {
        return url.href;
    }

    // https://developers.cloudflare.com/images/image-resizing/url-format/#format
    const params = ['format=auto', `width=${width}`];

    if (quality) {
        params.push(`quality=${quality}`);
    }

    const paramsString = params.join(',');

    return `${cdnImageHost}/cdn-cgi/image/${paramsString}/${normalizeSrc(url.href)}`;
};

const normalizeSrc = (src: string) => (src[0] === '/' ? src.slice(1) : src);

export { CloudflareLoader, UmbracoLoader };
